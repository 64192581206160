<template>
      <stats-card
        :title='country.title'
        :sub-title='country.subTitle'
        :type="country.type"
        :icon="country.icon">
      <div slot="footer">
        <base-dropdown title-tag="a" title="Change" icon="tim-icons icon-refresh-02"
          >


        </base-dropdown>
      </div>
      </stats-card>

</template>
<script>
import StatsCard from 'src/components/Cards/StatsCard';
export default {
  components: {
    StatsCard,
  },
  data() {
    return {
      country: {
        title: 'Afghanistan', 
        subTitle: 'My Country', 
        type: 'warning', 
        icon:'tim-icons icon-world', 
      },
      countries: [
        {name: 'Australia'},
        {name: 'Australia'},
      ],
      things: "this"
    };
  },
  methods: {
    updateProfile() {
      alert('Your data: ' + JSON.stringify(this.user));
    }
  }
};
</script>
<style></style>
