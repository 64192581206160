<template>
  <div class="row">
    <!-- Cards -->
    <div class="col-lg-4 col-md-6">
      <stats-card
        :title='profile.title'
        :sub-title='profile.subTitle'
        :type="profile.type"
        :icon="profile.icon">
      <!--<div slot="footer">
        <a class="nav-link" @click="editProfile()">
              <i class="tim-icons icon-refresh-02" ></i> Edit Profile
            </a>
      </div>-->
      </stats-card>

      <modal :show.sync="modalCountry" headerClasses="justify-content-center" class="modal modal-black show">
          <h4 slot="header" class="title title-up">Edit Country</h4>
          <br>
          <div class="row">
            <div class="col-md-12">
              <base-input>
                <el-select
                  class="select-info"
                  size="medium"
                  placeholder=""
                  filterable
                  v-model="newUserCountry"
                >
                  <el-option
                    v-for="option in newCountries"
                    class="select-info"
                    :value="option.search"
                    :label="option.search"
                    :key="option.search"
                  >
                  </el-option>
                </el-select>
              </base-input>
            </div>
          </div>
          <br>
          <div class="modal-footer">
            <base-button type="info" @click.native="modalCountry = false">Cancel</base-button>
            <base-button type="success" @click.native="updateUserCountry()">Save</base-button>
          </div>
        </modal>

      <stats-card
        :title='country.title'
        :sub-title='country.subTitle'
        :type="country.type"
        :icon="country.icon">
      <div slot="footer">
        <a class="nav-link" @click="changeCountry">
              <i class="tim-icons icon-refresh-02" ></i> Change
            </a>
      </div>
      </stats-card>
      
    </div>

    <!-- Statistics -->
    <div class="col-lg-5" :class="{ 'text-right': isRTL }">
      <card type="chart">
        <template slot="header">
          <h4 class="card-title">
            Total Records by Type
          </h4>
        </template>
        <div class="chart-area">
          <bar-chart
            style="height: 100%"
            :chart-data="newblueBarChart.chartData"
            :gradient-stops="newblueBarChart.gradientStops"
            :extra-options="newblueBarChart.extraOptions"
          >
          </bar-chart>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import BarChart from '@/components/Charts/BarChart';
import * as chartConfigs from '@/components/Charts/config';
import StatsCard from 'src/components/Cards/StatsCard';
import config from '@/config';
import cardCountry from 'src/pages/Components/cardCountry';
import swal from 'sweetalert2';
import SearchService from '@/services/SearchService'
import Vue from "vue"
import { Modal, BaseAlert } from 'src/components'
import { Select, Option, Loading } from 'element-ui';
import UserService from '@/services/UserService'

export default {
  components: {
    BarChart,
    StatsCard,
    cardCountry,
    Modal,
    BaseAlert,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      modalCountry: false,
      newUserCountry: window.GLOBALCOUNTRY,
      newCountries: '',
      country: {
        title: '', 
        subTitle: 'My Country', 
        type: 'warning', 
        icon:'tim-icons icon-world', 
      },
      profile: {
        title: '', 
        subTitle: 'Hello', 
        type: 'danger', 
        icon:'tim-icons icon-single-02', 
      },
      countries: {},
      newblueBarChart: {
        extraOptions: chartConfigs.barChartOptions,
        chartData: {
          labels: ['People', 'Orgs', 'Events', 'Reports', 'Tribes'],
          datasets: [
            {
              fill: true,
              borderColor: config.colors.info,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              data: []
            }
          ]
        },
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0]
      }
    };
  },
  computed: {
    enableRTL() {
      return this.$route.query.enableRTL;
    },
    isRTL() {
      return this.$rtl.isRTL;
    },
  },
  mounted() {
    this.loadDetails()
  },
  created() {
    //this.getCounts()
  },
  activated() {
    //this.getCounts()
    //this.profile.title = window.GLOBALUSERNAME
    //this.country.title = window.GLOBALCOUNTRY
  },
  methods: {
    loadDetails() {
      this.getNations()
    this.getCounts()
    this.profile.title = window.GLOBALUSERNAME
    this.country.title = window.GLOBALCOUNTRY
    this.i18n = this.$i18n;
    if (this.enableRTL) {
      this.i18n.locale = 'ar';
      this.$rtl.enableRTL();
    }
    },
  beforeDestroy() {
    if (this.$rtl.isRTL) {
      this.i18n.locale = 'en';
      this.$rtl.disableRTL();
    }
  },
  // Get and fill user info
  async getData(){
    let userInfo = await Vue.prototype.$Amplify.Auth.currentAuthenticatedUser()
    this.profile.title = userInfo.attributes.name
  },
    async getNations() {
      this.newCountries = window.GLOBALNATIONS

      // activeNations.data.forEach(country => {
      //   const id = country.search
      //   this.countries[id] = country.search
      // });
    },
    async getCounts() {
      const getEvents = await SearchService.getCountEvents()
      const getSouls = await SearchService.getCountSouls()
      const getTribes = await SearchService.getCountTribes()
      const getReports = await SearchService.getCountReports()
      const getOrganisations = await SearchService.getCountOrganisations()

      this.newblueBarChart = {
        extraOptions: chartConfigs.barChartOptions,
        chartData: {
          labels: ['People', 'Orgs', 'Events', 'Reports', 'Tribes'],
          datasets: [
            {
              fill: true,
              borderColor: config.colors.info,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              data: [getSouls.data,getOrganisations.data,getEvents.data,getReports.data,getTribes.data]
            }
          ]
        },
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0]
      }
    },
    editProfile(){
      this.getCounts()
    },
    async updateUserCountry() {
      let loading = Loading.service()
      await UserService.saveUser({
        id: window.GLOBALUSERID,
        country: this.newUserCountry
        })
      window.GLOBALCOUNTRY = this.newUserCountry
      this.modalCountry = false
      this.loadDetails()
      loading.close()
    },
    changeCountry() {
      this.modalCountry = true
    },
  },
};
</script>
<style></style>
